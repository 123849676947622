<template>
  <div class="navigation__header-languages-mobile" id="switch-language-mobile">
    <div class="langs-select-mobile">
      <div v-for="(lang, i) in langs" :key="`lang-${i}`" :class="{ active: $i18n.locale === lang }" @click="setLanguage(lang)">
        {{ lang }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SwitchLocale",
  data() {
    return {
      langs: ["en", "de", "fr"],
      currentLang: "",
    };
  },
  methods: {
    setLanguage(lang) {
      this.$i18n.locale = lang;
      this.updateLanguage();
    },
    updateLanguage() {
      localStorage.setItem("lang", this.$i18n.locale);
      window.location.href = "/";
    },
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.$i18n.locale = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", this.$i18n.locale);
    }
  },
  watch: {
    currentLang(newLang) {
      localStorage.setItem("lang", newLang);
    },
  },
};
</script>
<style>
*:focus {
  outline: none;
}
.active{
  color:#03aad9;
}

.langs-select-mobile div {
  display: inline-block;
  margin-right: 5px; /* optional: add some space between the language options */
}

.langs-select-mobile {
  color: white;
  height: 35px;
  padding: 5px 0px;
  text-transform: uppercase;
  appearance: none;
  display: flex;
  align-self: center;
}

@media (min-width: 992px){
 .navigation__header-languages-mobile {
    display: none;
  }
}

@media (max-width: 390px) {
  .navigation__header-languages-mobile {
    height: 24px;
  }
  .langs-select-mobile {
    height: 28px;
    padding: 0px !important;
  }
}
</style>