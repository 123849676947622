import Api from "@/apis/Api.js";

let lang = localStorage.getItem('lang');

if (!lang) {
  lang = 'de';
  localStorage.setItem('lang', 'de');
}

export default {

    getCompany(slug) {
        return Api.get(`/pages?locale=${lang}&slug=` + slug);
    },

    getTeam() {
        return Api.get(`/teams?locale=${lang}`);
    },

    getCSR() {
        return Api.get('/section_csr?locale=de');
    },

    getHomepageSlider() {
        return Api.get(`/sliders?locale=${lang}`);
    },

    getHomepageValues() {
        return Api.get(`/values_carousel?locale=${lang}`);
    },

    getHomepagePartners() {
        return Api.get('/partners?locale=de');
    },

    getContact() {
        return Api.get('/contact?locale=de');
    },

    getSocial() {
        return Api.get('/socialMedia');
    },

    getCareers() {
        return Api.get('/careers?locale=de');
    },

    getSingleCareer(title) {
        return Api.get('/career/view?locale=ro&title=' + title);
    },

    getCertificates() {
        return Api.get('/certifications?locale=de');
    },

    getCatalogues() {
        return Api.get('/catalogues?locale=de');
    },

    getProjects(page, categories = null, zones = null, years = null, services = null) {
        return Api.get('/portofolios', {
            params: {
                locale: lang,
                page: page,
                categories: categories,
                zones: zones,
                years: years,
                services: services
            }
        });
    },

    getFilters() {
        return Api.get('/filters?locale=de');
    },

    getSingleProject(slug) {
        return Api.get(`/portofolio?locale=${lang}&slug=` + slug);
    },

    getService(slug) {
        return Api.get(`/services?locale=${lang}&slug=` + slug);
    },
    getAllServices() {
        return Api.get(`/services/all?locale=${lang}`);
    },

    subscribe(email) {
        return Api.post('/newsletter/store?email=' + email);
    },
    getMenu() {
        return Api.get(`/menus?locale=${lang}`);
    },
    getHistory() {
        return Api.get(`/history?locale=${lang}`);
    },
};
